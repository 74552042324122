@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'RetroGaming';
  src: local('RetroGaming'), url('./font/RetroGaming.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-Regular'), url('./font/Ubuntu-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.c-font {
  font-family: 'RetroGaming', serif;
}

.p-font {
  font-family: 'Ubuntu', serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
header span {
  font-family: 'RetroGaming', serif;
}

p, td, th {
  font-family: 'Ubuntu', serif;
}

.yellow-font {
  color: #F3C760;
}

.tan-font {
  color: #F4E4A6;
}

.terminal-button {
  @apply bg-black mt-3 text-white mr-3 border border-green-400 rounded px-4 py-2 transition duration-200 ease-in-out transform hover:scale-105 font-mono;
}
.terminal-button:hover, .terminal-button:focus {
  @apply yellow-font;
}

.green-input {
  background-color: black;
  color: white;
  border: 1px solid #F3C760;
  padding: 8px;
  font-size: 16px;
  width: 290px;
}

.green-input:focus {
  outline: none;
  border-color: white; /* Example alternative focus style */
  box-shadow: 0 0 3px white; /* Example alternative focus style */
}

body {
  margin: 0;
  font-family: ClaconFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fde589;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add this to your global CSS file */
.terminal-table {
  @apply text-green-300 bg-black border-green-700;
}

.terminal-table thead tr {
  @apply bg-gray-800;
}

.terminal-table thead th {
  @apply uppercase text-green-400 border-green-700;
}

.terminal-table tbody tr:hover {
  @apply bg-gray-700;
}

.terminal-table td, .terminal-table th {
  @apply border border-green-700;
}

.terminal-table a {
  @apply text-green-400 hover:text-green-200;
}

.terminal-table .tx-id {
  @apply break-all;
}

/* Use this to truncate long strings with a tooltip on hover */
.truncate-with-tooltip {
  @apply truncate;
  position: relative;
  cursor: pointer;
}

.truncate-with-tooltip::after {
  content: attr(data-tip);
  @apply absolute hidden p-2 text-sm bg-gray-700 text-white rounded;
  top: 100%;
  left: 0;
  white-space: nowrap;
}

.truncate-with-tooltip:hover::after {
  @apply block;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate 6s linear infinite;
} 