.fixed-header {
    background-color: black;
    padding: 10px;
    display: flex;
    align-items: center;
}

.logo {
    /* Adjust size and other styles as necessary */
}

.header-text {
    color: white; 
}

.content {
    background-color: #fde589;
    padding-top: 60px; /* Adjust based on header height */
}

button {
    background-color: black;
    color: white;
    border: none;
    border-radius: 0;
    padding: 10px 20px;
}

table {
    color: black;
}

table th, table td {
    color: white;
}

.bg-purple {
    background: #f3e1f8;
    color: rgb(203, 53, 242);
}

.bg-haze {
    background: #e5e1ff;
    color: #6953E8;
}

.bg-green-c {   
    background: #ccF2c3;
    color: #009672;   
}

.bg-legendary {
    color: #f19100;
    background: #ffe99c;
}

.bg-mythic {
    color: #564890;
    background: #b5Acd9;
}

.verify-content {
    display: none;
}

.bg-black {
    background: #000;
    color: #fff;
}

.bg-doginals {
    background: #fde589;
}
 
.verify-button, .logout-button {
    background: #fde589;
    text-transform: uppercase;
    font-size: 20px;
    color: black;
  }
/* Add other styles as necessary for your page */
